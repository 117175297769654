var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "3"
    }
  }, [_vm.$options.components['health-care-category'] ? [_c('health-care-category')] : _vm._e()], 2), _c('b-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "9"
    }
  }, [_c('span', {
    staticClass: "text-grey1"
  }, [_vm._v("Order Medicine > Search Specific Item ")]), _vm.list && _vm.list.length ? _c('sections-slider', {
    attrs: {
      "list": _vm.list,
      "subTitle": "Health Care",
      "hasInnerBackground": true,
      "hasButton": false,
      "withSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          attrs: {
            "itemLogo": true,
            "product": listItem,
            "incrementDecrementKey": false,
            "marketRoute": {
              name: listItem.name == 'Health Care' ? "health-care" : "healthcare-market-view",
              params: {
                market: listItem.name,
                id: listItem._id,
                items: listItem.externalAttributes
              }
            }
          }
        })], 1)];
      }
    }], null, false, 153925513)
  }) : _vm._e(), _c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.itemSuggestions,
      "subTitle": "Most Popular",
      "showTitle": true,
      "hasBackground": false,
      "hasButton": true,
      "withSlider": true,
      "viewMorePage": {
        name: "health-care-listing",
        params: {
          items: "Most Popular"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box ml-2 mr- 2"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }])
  }), _c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.newArrivals,
      "subTitle": "Daily Essentials",
      "showTitle": true,
      "hasBackground": false,
      "hasButton": true,
      "withSlider": true,
      "viewMorePage": {
        name: "health-care-listing",
        params: {
          items: "Daily Essentials"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var listItem = _ref3.listItem;
        return [_c('div', {
          staticClass: "item-box ml-2 mr- 2"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }