<template>
  <b-container>
    <b-row>
      <b-col cols="3" class="mt-4">
        <template v-if="$options.components['health-care-category']">
          <health-care-category />
        </template>
      </b-col>
      <b-col cols="9" class="my-4">
        <span class="text-grey1">Order Medicine > Search Specific Item </span>
        <sections-slider
          v-if="list && list.length"
          :list="list"
          subTitle="Health Care"
          :hasInnerBackground="true"
          :hasButton="false"
          :withSlider="true"
        >
          <template slot="default" slot-scope="{ listItem }">
            <div class="item-box">
              <items-item
                :itemLogo="true"
                :product="listItem"
                :incrementDecrementKey="false"
                :marketRoute="{
                name: listItem.name == 'Health Care' ? `health-care` : `healthcare-market-view`,
                params: {
                  market: listItem.name,
                  id: listItem._id,
                  items: listItem.externalAttributes
                },
              }"
              ></items-item>
            </div>
          </template>
        </sections-slider>
        <sections-slider
          :list="itemSuggestions"
          subTitle="Most Popular"
          :showTitle="true"
          :hasBackground="false"
          :hasButton="true"
          class="suggested-slider"
          :withSlider="true"
          :viewMorePage="{
        name: `health-care-listing`,
        params: {
          items: `Most Popular`,
        },
      }"
        >
          <template slot="default" slot-scope="{ listItem }">
            <div class="item-box ml-2 mr- 2">
              <items-item
                :itemNoBg="true"
                :product="listItem"
                :incrementDecrementKey="true"
                class="suggester-div"
              ></items-item>
            </div>
          </template>
        </sections-slider>
        <sections-slider
          :list="newArrivals"
          subTitle="Daily Essentials"
          :showTitle="true"
          :hasBackground="false"
          :hasButton="true"
          class="suggested-slider"
          :withSlider="true"
          :viewMorePage="{
        name: `health-care-listing`,
        params: {
          items: `Daily Essentials`,
        },
      }"
        >
          <template slot="default" slot-scope="{ listItem }">
            <div class="item-box ml-2 mr- 2">
              <items-item
                :itemNoBg="true"
                :product="listItem"
                :incrementDecrementKey="true"
                class="suggester-div"
              ></items-item>
            </div>
          </template>
        </sections-slider>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      list: (state) => state.healthCare.specificItemsList,
      itemSuggestions: (state) => state.healthCare.itemSuggestions,
      newArrivals: (state) => state.healthCare.newArrivals,
    }),
  },
  methods: {},

  async mounted() {
    this.isLoading = true;
    // await this.$store.dispatch("healthCare/fetchSpecificItemsList",this.$route.params.id);
    await this.$store.dispatch(
      "healthCare/fetchSpecificItemsList",
      "628371f94b367714f38740e6"
    );
    await this.$store.dispatch(
      "healthCare/fetchAllItems",
      this.$route.params.market
    );
    this.isLoading = false;
  },

  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.text-grey1 {
  color: #aaa;
  background-color: transparent;
  margin-top: 20px;
  margin-left: 10px;
}
</style>
